import './DarkMode.scss'
import { useEffect, useRef, useState } from 'react'
import darkModePhonesNoShadow from '../../../images/dark_mode_phones_no_shadow.png'
import lightModePhonesNoShadow from '../../../images/light_mode_phones_no_shadow.png'
import darkModeShadow from '../../../images/dark_mode_shadow.png'
import Page from '../../Page'
import useWindowSize from '../../../Hooks/useWindowSize'
import { AnimatePresence, motion, useInView, useMotionValue, useSpring, useTransform } from "motion/react"
import { Switch } from '@mui/material'

function DarkMode(props) {

    const [darkMode, setDarkMode] = useState(true)
    const [, setImageHeight] = useState(0)
    const ref = useRef(null)
    const lightModeRef = useRef(null)
    const darkModeRef = useRef(null)
    const titleRef = useRef(null)
    const timerRef = useRef(null)
    const scrollTriggerInView = useInView(ref, {once: true})
    // eslint-disable-next-line
    const {width, height} = useWindowSize();

    const y = useMotionValue(0)
    const yAnimation = useSpring(useTransform(y, [0, 1], [50, -50]), {stiffness: 50, damping: 10, bounce: 0.1, mass: 10})
    const xAnimation = useSpring(useTransform(y, [0, 1], [-400, -300]), {stiffness: 50, damping: 10, bounce: 0.1, mass: 10})

    useEffect(() => {
        // Force image to resize on scroll
        window.addEventListener('scroll', (event) => setImageHeight(darkModeRef?.current?.clientHeight))
    }, [])

    useEffect(() => {
        if(scrollTriggerInView){
            y.set(1)

            let timer = setTimeout(() => {
                setDarkMode(currMode => !currMode)
                flipSwitch()
            }, 3000);
            return () => clearTimeout(timer);
        }
    // eslint-disable-next-line
    }, [scrollTriggerInView])

    const flipSwitch = () => {
        timerRef.current = setInterval(() => setDarkMode(currMode => !currMode), 6000);
        return () => clearTimeout(timerRef.current);
    }

    const DarkPhone = <motion.img 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        style={{ y: yAnimation, x: '-50%'}}
        src={darkModePhonesNoShadow}
        ref={darkModeRef}
        className="Dark-mode-phones pb-[4vh]" 
        alt="Dark mode phones"
    />

    const LightPhone = <motion.img 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        style={{ y: yAnimation, x: '-50%'}}
        src={lightModePhonesNoShadow}
        ref={lightModeRef}
        className="Dark-mode-phones pb-[4vh]" 
        alt="Dark mode phones"
    />


    return (
        <Page bgColour={"#343638"} className="dark-mode-page" style={{ height: `calc(5vh + 5vh + 38px + ${`${darkModeRef?.current?.clientHeight}px` ?? `${lightModeRef?.current?.clientHeight}` ?? '80vh'} + ${titleRef?.current?.clientHeight}px)` }}>
            {/* Need to preload these images so the light mode doesnt load for the first time on animation */}
            <img src={lightModePhonesNoShadow} alt="Light mode example" style={{display: 'none'}}/>
            <img src={darkModePhonesNoShadow} alt="Dark mode example" style={{display: 'none'}}/>
            <div className="Dark-mode-light-bg"></div>
                <h2 className="Dark-mode-title title-xlarge" ref={titleRef}>Optimised for dark mode</h2>
                <div className="Dark-mode-switch-container">
                    <Switch
                        checked={darkMode}
                        onChange={() => {
                            setDarkMode(!darkMode)
                            clearInterval(timerRef.current)
                        }}
                        color='primary'
                        size="large"
                    />
                </div>
                <div ref={ref}>
                    {/* Need to use two different images here so the fade in/out animations work */}
                    <AnimatePresence>
                        {darkMode && DarkPhone}
                    </AnimatePresence>
                    <AnimatePresence>
                        {!darkMode && LightPhone}
                    </AnimatePresence>
                    <motion.img
                        style={{ x: xAnimation, height: darkModeRef?.current?.clientHeight ?? lightModeRef?.current?.clientHeight, left: ((darkModeRef?.current ?? lightModeRef?.current)?.getBoundingClientRect().left ?? 0) + 300 }}
                        src={darkModeShadow}
                        className="Dark-mode-phones-shadow" 
                        alt="Dark mode phones"
                    />
                </div>
        </Page>
    )
}

export default DarkMode